import { AttackDetection } from "./resources/attackDetection.js";
import { AuthenticationManagement } from "./resources/authenticationManagement.js";
import { Cache } from "./resources/cache.js";
import { ClientPolicies } from "./resources/clientPolicies.js";
import { Clients } from "./resources/clients.js";
import { ClientScopes } from "./resources/clientScopes.js";
import { Components } from "./resources/components.js";
import { Groups } from "./resources/groups.js";
import { IdentityProviders } from "./resources/identityProviders.js";
import { Realms } from "./resources/realms.js";
import { Roles } from "./resources/roles.js";
import { ServerInfo } from "./resources/serverInfo.js";
import { Users } from "./resources/users.js";
import { UserStorageProvider } from "./resources/userStorageProvider.js";
import { WhoAmI } from "./resources/whoAmI.js";
import { getToken } from "./utils/auth.js";
import { defaultBaseUrl, defaultRealm } from "./utils/constants.js";
export class KeycloakAdminClient {
    // Resources
    users;
    userStorageProvider;
    groups;
    roles;
    clients;
    realms;
    clientScopes;
    clientPolicies;
    identityProviders;
    components;
    serverInfo;
    whoAmI;
    attackDetection;
    authenticationManagement;
    cache;
    // Members
    baseUrl;
    realmName;
    accessToken;
    refreshToken;
    #requestOptions;
    #globalRequestArgOptions;
    #tokenProvider;
    constructor(connectionConfig) {
        this.baseUrl = connectionConfig?.baseUrl || defaultBaseUrl;
        this.realmName = connectionConfig?.realmName || defaultRealm;
        this.#requestOptions = connectionConfig?.requestOptions;
        this.#globalRequestArgOptions = connectionConfig?.requestArgOptions;
        // Initialize resources
        this.users = new Users(this);
        this.userStorageProvider = new UserStorageProvider(this);
        this.groups = new Groups(this);
        this.roles = new Roles(this);
        this.clients = new Clients(this);
        this.realms = new Realms(this);
        this.clientScopes = new ClientScopes(this);
        this.clientPolicies = new ClientPolicies(this);
        this.identityProviders = new IdentityProviders(this);
        this.components = new Components(this);
        this.authenticationManagement = new AuthenticationManagement(this);
        this.serverInfo = new ServerInfo(this);
        this.whoAmI = new WhoAmI(this);
        this.attackDetection = new AttackDetection(this);
        this.cache = new Cache(this);
    }
    async auth(credentials) {
        const { accessToken, refreshToken } = await getToken({
            baseUrl: this.baseUrl,
            realmName: this.realmName,
            credentials,
            requestOptions: this.#requestOptions,
        });
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }
    registerTokenProvider(provider) {
        if (this.#tokenProvider) {
            throw new Error("An existing token provider was already registered.");
        }
        this.#tokenProvider = provider;
    }
    setAccessToken(token) {
        this.accessToken = token;
    }
    async getAccessToken() {
        if (this.#tokenProvider) {
            return this.#tokenProvider.getAccessToken();
        }
        return this.accessToken;
    }
    getRequestOptions() {
        return this.#requestOptions;
    }
    getGlobalRequestArgOptions() {
        return this.#globalRequestArgOptions;
    }
    setConfig(connectionConfig) {
        if (typeof connectionConfig.baseUrl === "string" &&
            connectionConfig.baseUrl) {
            this.baseUrl = connectionConfig.baseUrl;
        }
        if (typeof connectionConfig.realmName === "string" &&
            connectionConfig.realmName) {
            this.realmName = connectionConfig.realmName;
        }
        this.#requestOptions = connectionConfig.requestOptions;
    }
}
