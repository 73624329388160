import "./main.css";
import { jsxs as y, Fragment as V, jsx as r } from "react/jsx-runtime";
import { useState as O, createContext as K, useContext as z, useCallback as Y, useEffect as Q, useMemo as S, forwardRef as X, Fragment as Z, useId as he } from "react";
import { Modal as pe, Button as x, Popover as ge, FormGroup as ee, ValidatedOptions as v, Select as te, SelectOption as ne, Switch as ye, TextInput as re, TextArea as be, AlertGroup as Ce, Alert as Ie, AlertVariant as L, AlertActionCloseButton as ve, InputGroup as le, ButtonVariant as $, Checkbox as Te, Radio as Se, Text as ke, Title as xe, Card as Oe, CardHeader as Ae, CardTitle as we, CardBody as Ve, Grid as qe, GridItem as H, PageSection as Fe, JumpLinks as _e, JumpLinksItem as Le } from "@patternfly/react-core";
import { useFormContext as ae, Controller as q, useController as se, FormProvider as Ne, useWatch as Re } from "react-hook-form";
import { HelpIcon as M, CubeIcon as De, PaypalIcon as Ee, InstagramIcon as Pe, BitbucketIcon as $e, MicrosoftIcon as He, TwitterIcon as Me, StackOverflowIcon as Ge, OpenshiftIcon as Be, LinkedinIcon as je, GoogleIcon as Ue, GitlabIcon as Je, FacebookSquareIcon as We, GithubIcon as Ke, MinusCircleIcon as ze, PlusCircleIcon as Ye } from "@patternfly/react-icons";
import { get as G } from "lodash-es";
const _t = ({
  modalTitle: e,
  continueLabel: t,
  cancelLabel: n,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: s,
  onContinue: o,
  component: i = x,
  children: c,
  ...u
}) => {
  const [p, f] = O(!1);
  return /* @__PURE__ */ y(V, { children: [
    /* @__PURE__ */ r(
      i,
      {
        variant: s,
        onClick: () => f(!0),
        isDisabled: l,
        children: a
      }
    ),
    /* @__PURE__ */ r(
      pe,
      {
        variant: "small",
        ...u,
        title: e,
        isOpen: p,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), o();
              },
              children: t
            },
            "confirm"
          ),
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: n
            },
            "cancel"
          )
        ],
        children: c
      }
    )
  ] });
};
function Qe(e, t) {
  const n = K(t);
  return n.displayName = e, n;
}
function Xe(e) {
  return e != null;
}
function Ze(e) {
  const t = z(e);
  if (Xe(t))
    return t;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function et(e, t, n) {
  const [a, l] = O(
    () => e.getItem(t) ?? n
  ), s = Y((o) => {
    l(o), e.setItem(t, o);
  }, []);
  return Q(() => {
    l(e.getItem(t) ?? n), window.addEventListener("storage", o);
    function o(i) {
      i.storageArea === e && (i.key === null || i.key === t) && l(i.newValue ?? n);
    }
    return () => window.removeEventListener("storage", o);
  }, [e, t]), [a, s];
}
function tt(e, t, n) {
  const a = S(
    () => JSON.stringify(n),
    [n]
  ), [l, s] = et(
    e,
    t,
    a
  ), o = S(() => JSON.parse(l), [l]), i = Y(
    (c) => s(JSON.stringify(c)),
    []
  );
  return [o, i];
}
const oe = Qe(
  "HelpContext",
  void 0
), nt = () => Ze(oe), Lt = ({ children: e }) => {
  const [t, n] = tt(localStorage, "helpEnabled", !0);
  function a() {
    n(!t);
  }
  return /* @__PURE__ */ r(oe.Provider, { value: { enabled: t, toggleHelp: a }, children: e });
}, ie = ({
  helpText: e,
  fieldLabelId: t,
  noVerticalAlign: n = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = nt();
  return l ? /* @__PURE__ */ r(ge, { bodyContent: e, children: /* @__PURE__ */ y(V, { children: [
    !a && /* @__PURE__ */ r(
      "button",
      {
        "data-testid": `help-label-${t}`,
        "aria-label": t,
        onClick: (s) => s.preventDefault(),
        className: "pf-c-form__group-label-help",
        children: /* @__PURE__ */ r(M, { noVerticalAlign: n })
      }
    ),
    a && /* @__PURE__ */ r(M, { noVerticalAlign: n })
  ] }) }) : null;
}, F = ({
  name: e,
  label: t,
  labelIcon: n,
  error: a,
  children: l,
  ...s
}) => /* @__PURE__ */ r(
  ee,
  {
    label: t || e,
    fieldId: e,
    labelIcon: n ? /* @__PURE__ */ r(ie, { helpText: n, fieldLabelId: e }) : void 0,
    helperTextInvalid: a?.message,
    validated: a ? v.error : v.default,
    ...s,
    children: l
  }
), rt = ({
  name: e,
  label: t,
  options: n,
  controller: a,
  variant: l,
  ...s
}) => {
  const {
    control: o,
    formState: { errors: i }
  } = ae(), [c, u] = O(!1);
  return /* @__PURE__ */ r(
    F,
    {
      name: e,
      label: t,
      isRequired: a.rules?.required === !0,
      error: i[e],
      children: /* @__PURE__ */ r(
        q,
        {
          ...a,
          name: e,
          control: o,
          render: ({ field: { onChange: p, value: f } }) => /* @__PURE__ */ r(
            te,
            {
              ...s,
              toggleId: e,
              onToggle: (d) => u(d),
              selections: typeof n[0] != "string" && n.find(
                (d) => d.key === f[0]
              )?.value || f,
              onSelect: (d, h) => {
                if (l === "typeaheadmulti") {
                  const m = h.toString();
                  f.includes(m) ? p(f.filter((T) => T !== m)) : p([...f, m]);
                } else
                  p([h]), u(!1);
              },
              onClear: (d) => {
                d.stopPropagation(), p([]);
              },
              isOpen: c,
              variant: l,
              validated: i[e] ? v.error : v.default,
              children: n.map((d) => /* @__PURE__ */ r(
                ne,
                {
                  value: typeof d == "string" ? d : d.key,
                  children: typeof d == "string" ? d : d.value
                },
                typeof d == "string" ? d : d.key
              ))
            }
          )
        }
      )
    }
  );
}, Nt = (e) => {
  const t = e.defaultValue ?? !1, { control: n } = ae();
  return /* @__PURE__ */ r(
    F,
    {
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ r(
        q,
        {
          control: n,
          name: e.name,
          defaultValue: t,
          render: ({ field: { onChange: a, value: l } }) => /* @__PURE__ */ r(
            ye,
            {
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              labelOff: e.labelOff,
              isChecked: e.stringify ? l === "true" : l,
              onChange: (s, o) => {
                const i = e.stringify ? s.toString() : s;
                e.onChange?.(s, o), a(i);
              }
            }
          )
        }
      )
    }
  );
}, D = X(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(re, { ...t, ref: n, onChange: (l, s) => e?.(s) }));
D.displayName = "TextInput";
const Rt = (e) => {
  const { labelIcon: t, ...n } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: s, fieldState: o } = se({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ r(
    F,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: a,
      error: o.error,
      children: /* @__PURE__ */ r(
        D,
        {
          isRequired: a,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? v.error : v.default,
          isDisabled: e.isDisabled,
          ...n,
          ...s
        }
      )
    }
  );
}, E = X(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(be, { ...t, ref: n, onChange: (l, s) => e?.(s) }));
E.displayName = "TextArea";
const Dt = (e) => {
  const t = !!e.rules?.required, n = e.defaultValue ?? "", { field: a, fieldState: l } = se({
    ...e,
    defaultValue: n
  });
  return /* @__PURE__ */ r(
    F,
    {
      isRequired: t,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ r(
        E,
        {
          isRequired: t,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? v.error : v.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, ce = K(void 0), Et = () => z(ce), Pt = ({ children: e }) => {
  const [t, n] = O([]), a = (o) => {
    n((i) => i.filter((c) => c.id !== o));
  }, l = (o, i = L.success, c) => {
    n([
      {
        id: Math.random() * 100,
        message: o,
        variant: i,
        description: c
      },
      ...t
    ]);
  }, s = (o) => {
    l(o, L.danger);
  };
  return /* @__PURE__ */ y(ce.Provider, { value: { addAlert: l, addError: s }, children: [
    /* @__PURE__ */ r(Ce, { isToast: !0, "data-testid": "alerts", children: t.map(({ id: o, variant: i, message: c, description: u }) => /* @__PURE__ */ r(
      Ie,
      {
        isLiveRegion: !0,
        variant: L[i],
        variantLabel: "",
        title: c,
        actionClose: /* @__PURE__ */ r(
          ve,
          {
            title: c,
            onClose: () => a(o)
          }
        ),
        timeout: !0,
        onTimeout: () => a(o),
        children: u && /* @__PURE__ */ r("p", { children: u })
      },
      o
    )) }),
    e
  ] });
}, $t = ({ icon: e }) => {
  const t = lt(e);
  return /* @__PURE__ */ r(t, { size: "lg", alt: e });
};
function lt(e) {
  switch (e) {
    case "github":
      return Ke;
    case "facebook":
      return We;
    case "gitlab":
      return Je;
    case "google":
      return Ue;
    case "linkedin":
    case "linkedin-openid-connect":
      return je;
    case "openshift-v3":
    case "openshift-v4":
      return Be;
    case "stackoverflow":
      return Ge;
    case "twitter":
      return Me;
    case "microsoft":
      return He;
    case "bitbucket":
      return $e;
    case "instagram":
      return Pe;
    case "paypal":
      return Ee;
    default:
      return De;
  }
}
const at = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, st = ({
  t: e,
  form: t,
  supportedLocales: n
}) => {
  const a = n.map((l) => ({
    key: l,
    value: at(l) || ""
  }));
  return /* @__PURE__ */ r(Ne, { ...t, children: /* @__PURE__ */ r(
    rt,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a
    }
  ) });
}, ue = (e) => e?.includes("${"), P = (e) => e.substring(2, e.length - 1), N = (e, t, n) => (ue(t) ? e(P(t)) : t) || n, R = (e, t) => N(e, t.displayName, t.name), ot = ["username", "firstName", "lastName", "email"], de = (e) => e && ot.includes(e), I = (e) => `${de(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`;
function Ht(e, t, n) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((s) => n(ue(s.toString()) ? P(s) : s))
    );
    t(I(a.field), {
      message: n(a.errorMessage, {
        ...l,
        defaultValue: a.field
      }),
      type: "server"
    });
  });
}
function A({
  required: e,
  validators: t
}) {
  return e || it(t);
}
function it(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Mt(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: t } = e;
  return B(t) ? !0 : typeof t != "object" || t === null || !("errors" in t) || !Array.isArray(t.errors) ? !1 : t.errors.every(B);
}
function B(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const w = ({
  t: e,
  form: t,
  attribute: n,
  renderer: a,
  children: l
}) => {
  const s = n.annotations?.inputHelperTextBefore, {
    formState: { errors: o }
  } = t, i = a?.(n);
  return /* @__PURE__ */ r(
    ee,
    {
      label: R(e, n) || "",
      fieldId: n.name,
      isRequired: A(n),
      validated: G(o, I(n.name)) ? "error" : "default",
      helperTextInvalid: e(G(o, I(n.name))?.message),
      labelIcon: s ? /* @__PURE__ */ r(ie, { helpText: s, fieldLabelId: n.name }) : void 0,
      children: i ? /* @__PURE__ */ y(le, { children: [
        l,
        i
      ] }) : l
    },
    n.name
  );
}, ct = ({
  t: e,
  form: t,
  attribute: n,
  renderer: a
}) => /* @__PURE__ */ r(w, { t: e, form: t, attribute: n, renderer: a, children: /* @__PURE__ */ r(
  ut,
  {
    t: e,
    form: t,
    "aria-label": R(e, n),
    name: I(n.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: R(e, n)
    })
  }
) }), ut = ({
  t: e,
  name: t,
  form: n,
  addButtonLabel: a,
  isDisabled: l = !1,
  defaultValue: s,
  id: o,
  ...i
}) => {
  const { register: c, setValue: u, control: p } = n, f = Re({
    name: t,
    control: p,
    defaultValue: s || ""
  }), d = S(() => Array.isArray(f) && f.length !== 0 ? f : s || [""], [f]), h = (b) => {
    k([...d.slice(0, b), ...d.slice(b + 1)]);
  }, m = () => {
    k([...d, ""]);
  }, T = (b, g) => {
    k([...d.slice(0, b), g, ...d.slice(b + 1)]);
  }, k = (b) => {
    const g = b.flatMap((_) => _);
    u(t, g, {
      shouldDirty: !0
    });
  };
  return Q(() => {
    c(t);
  }, [c]), /* @__PURE__ */ r("div", { id: o, children: d.map((b, g) => /* @__PURE__ */ y(Z, { children: [
    /* @__PURE__ */ y(le, { children: [
      /* @__PURE__ */ r(
        re,
        {
          "data-testid": t + g,
          onChange: (_) => T(g, _),
          name: `${t}.${g}.value`,
          value: b,
          isDisabled: l,
          ...i
        }
      ),
      /* @__PURE__ */ r(
        x,
        {
          "data-testid": "remove" + g,
          variant: $.link,
          onClick: () => h(g),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: d.length === 1 || l,
          children: /* @__PURE__ */ r(ze, {})
        }
      )
    ] }),
    g === d.length - 1 && /* @__PURE__ */ y(
      x,
      {
        variant: $.link,
        onClick: m,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !b || l,
        children: [
          /* @__PURE__ */ r(Ye, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, g)) });
}, j = (e) => {
  const { form: t, inputType: n, attribute: a } = e, l = A(a), s = n.startsWith("multiselect"), o = s ? Te : Se, i = a.validators?.options?.options || [];
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    q,
    {
      name: I(a.name),
      control: t.control,
      defaultValue: "",
      render: ({ field: c }) => /* @__PURE__ */ r(V, { children: i.map((u) => /* @__PURE__ */ r(
        o,
        {
          id: u,
          "data-testid": u,
          label: u,
          value: u,
          isChecked: c.value.includes(u),
          onChange: () => {
            s ? c.value.includes(u) ? c.onChange(
              c.value.filter((p) => p !== u)
            ) : c.onChange([...c.value, u]) : c.onChange([u]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        u
      )) })
    }
  ) });
}, U = (e) => {
  const { t, form: n, inputType: a, attribute: l } = e, [s, o] = O(!1), i = A(l), c = a === "multiselect", u = (h, m) => {
    c ? m.value.includes(h) ? m.onChange(m.value.filter((T) => T !== h)) : m.onChange([...m.value, h]) : m.onChange(h);
  }, p = l.validators?.options?.options || [], f = l.annotations?.inputOptionLabels, d = (h) => f ? t(P(f[h])) : h;
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    q,
    {
      name: I(l.name),
      defaultValue: "",
      control: n.control,
      render: ({ field: h }) => /* @__PURE__ */ r(
        te,
        {
          toggleId: l.name,
          onToggle: (m) => o(m),
          isCreatable: !0,
          onCreateOption: (m) => u(m, h),
          onSelect: (m, T) => {
            const k = T.toString();
            u(k, h), Array.isArray(h.value) || o(!1);
          },
          selections: h.value ? h.value : c ? [] : t("choose"),
          variant: c ? "typeaheadmulti" : "single",
          "aria-label": t("selectOne"),
          isOpen: s,
          isDisabled: l.readOnly,
          required: i,
          children: p.map((m) => /* @__PURE__ */ r(
            ne,
            {
              selected: h.value === m,
              value: m,
              children: d(m)
            },
            m
          ))
        }
      )
    }
  ) });
}, dt = (e) => {
  const { form: t, attribute: n } = e, a = A(n);
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    E,
    {
      id: n.name,
      "data-testid": n.name,
      ...t.register(I(n.name)),
      cols: n.annotations?.inputTypeCols,
      rows: n.annotations?.inputTypeRows,
      readOnly: n.readOnly,
      isRequired: a
    }
  ) });
}, C = (e) => {
  const { form: t, inputType: n, attribute: a } = e, l = A(a), s = n.startsWith("html") ? n.substring(4 + 2) : "text";
  return /* @__PURE__ */ r(w, { ...e, children: /* @__PURE__ */ r(
    D,
    {
      id: a.name,
      "data-testid": a.name,
      type: s,
      placeholder: a.annotations?.inputTypePlaceholder,
      readOnly: a.readOnly,
      isRequired: l,
      ...t.register(I(a.name))
    }
  ) });
}, me = {
  text: C,
  textarea: dt,
  select: U,
  "select-radiobuttons": j,
  multiselect: U,
  "multiselect-checkboxes": j,
  "html5-email": C,
  "html5-tel": C,
  "html5-url": C,
  "html5-number": C,
  "html5-range": C,
  "html5-datetime-local": C,
  "html5-date": C,
  "html5-month": C,
  "html5-time": C,
  "multi-input": ct
}, Gt = ({
  t: e,
  form: t,
  userProfileMetadata: n,
  supportedLocales: a,
  hideReadOnly: l = !1,
  renderer: s
}) => {
  const o = S(() => {
    if (!n.attributes)
      return [];
    const i = l ? n.attributes.filter(({ readOnly: c }) => !c) : n.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...n.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: i.filter(
        (u) => u.group === c.name
      )
    }));
  }, [
    l,
    n.groups,
    n.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ r(
    kt,
    {
      label: e("jumpToSection"),
      sections: o.filter((i) => i.attributes.length > 0).map(({ group: i, attributes: c }) => ({
        title: N(e, i.displayHeader, i.name) || e("general"),
        panel: /* @__PURE__ */ y("div", { className: "pf-c-form", children: [
          i.displayDescription && /* @__PURE__ */ r(ke, { className: "pf-u-pb-lg", children: N(e, i.displayDescription, "") }),
          c.map((u) => /* @__PURE__ */ r(
            mt,
            {
              t: e,
              form: t,
              supportedLocales: a,
              renderer: s,
              attribute: u
            },
            u.name
          ))
        ] })
      }))
    }
  );
}, mt = ({
  t: e,
  form: t,
  renderer: n,
  supportedLocales: a,
  attribute: l
}) => {
  const s = t.watch(
    I(l.name)
  ), o = S(
    () => ht(l, s),
    [l]
  ), i = me[o];
  return l.name === "locale" ? /* @__PURE__ */ r(
    st,
    {
      form: t,
      supportedLocales: a,
      t: e,
      attribute: l
    }
  ) : /* @__PURE__ */ r(
    i,
    {
      t: e,
      form: t,
      inputType: o,
      attribute: l,
      renderer: n
    }
  );
}, ft = "text";
function ht(e, t) {
  if (de(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return pt(n) ? n : gt(t) ? "multi-input" : ft;
}
const pt = (e) => typeof e == "string" && e in me, gt = (e) => Array.isArray(e) && e.length > 1, yt = "_title_1nyfo_2", bt = {
  title: yt
}, fe = ({
  id: e,
  title: t,
  headingLevel: n = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ r(
  xe,
  {
    headingLevel: n,
    size: a,
    className: bt.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: t
  }
), Ct = ({
  title: e,
  children: t,
  scrollId: n,
  className: a
}) => {
  const l = he();
  return /* @__PURE__ */ y(Oe, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ r(Ae, { className: "kc-form-panel__header", children: /* @__PURE__ */ r(we, { tabIndex: 0, children: /* @__PURE__ */ r(fe, { id: n, title: e }) }) }),
    /* @__PURE__ */ r(Ve, { className: "kc-form-panel__body", children: t })
  ] });
}, It = (e) => {
  const { title: t, children: n, scrollId: a, ...l } = e;
  return /* @__PURE__ */ r("section", { ...l, style: { marginTop: "var(--pf-global--spacer--lg)" }, children: /* @__PURE__ */ y(V, { children: [
    /* @__PURE__ */ r(fe, { id: a, title: t }),
    n
  ] }) });
}, vt = "_panel_cd9gh_1", Tt = "_sticky_cd9gh_5", J = {
  panel: vt,
  sticky: Tt
}, St = "kc-main-content-page-container", W = (e) => e.replace(/\s+/g, "-"), kt = ({
  label: e,
  sections: t,
  borders: n = !1,
  ...a
}) => {
  const l = S(
    () => t.filter(({ isHidden: s }) => !s),
    [t]
  );
  return /* @__PURE__ */ y(qe, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ r(H, { md: 8, sm: 12, children: l.map(({ title: s, panel: o }) => {
      const i = W(s.toLowerCase());
      return /* @__PURE__ */ r(Z, { children: n ? /* @__PURE__ */ r(
        Ct,
        {
          scrollId: i,
          title: s,
          className: J.panel,
          children: o
        }
      ) : /* @__PURE__ */ r(It, { scrollId: i, title: s, children: o }) }, s);
    }) }),
    /* @__PURE__ */ r(H, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ r(Fe, { className: J.sticky, children: /* @__PURE__ */ r(
      _e,
      {
        isVertical: !0,
        scrollableSelector: `#${St}`,
        label: e,
        offset: 100,
        children: l.map(({ title: s }) => {
          const o = W(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ r(
              Le,
              {
                href: `#${o}`,
                "data-testid": `jump-link-${o}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
};
export {
  Pt as AlertProvider,
  _t as ContinueCancelModal,
  Ct as FormPanel,
  Lt as Help,
  ie as HelpItem,
  $t as IconMapper,
  E as KeycloakTextArea,
  D as KeycloakTextInput,
  kt as ScrollForm,
  rt as SelectControl,
  Nt as SwitchControl,
  Dt as TextAreaControl,
  Rt as TextControl,
  Gt as UserProfileFields,
  Qe as createNamedContext,
  Xe as isDefined,
  Mt as isUserProfileError,
  St as mainPageContentId,
  Ht as setUserProfileServerError,
  Et as useAlerts,
  nt as useHelp,
  Ze as useRequiredContext,
  tt as useStoredState
};
